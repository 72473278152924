$btn-height: 35px;
$btn-width: 35px;
$btn-color: #484848;
$border: 0;
$gallery-box-shadow: 0;
$active-slide:#eee;

.product-img-gallery {
  width:100%;
  margin:0 auto;
  .product-img-header {
    margin:20px 0;
  }
  .product-img-middle {
    position: relative;
    display:flex;
    align-items:center;
    .product-img-body {
      position: relative;
      left:3px;
      width:calc(100% - 6px);
      box-shadow:$gallery-box-shadow;
      border:$border;
      padding-top: 65.8%;
      height: 0 !important;
      div {
        display:inline-block;
        vertical-align: top;
        width:100%;
        height:100%;
        position: relative;
        a {
          display: block;
          background-size:cover;
          background-position: center;
          background-repeat: no-repeat;
          height:100%;
        }
        img {
          opacity:1;
          width: 100%;
        }
      }
    }
    .cycle-btn {
      position: absolute;
      bottom: 1px;
      width:$btn-width;
      height:$btn-height;
      text-align:center;
      display:inline-block;
      background:$btn-color;
      display:flex;
      justify-content:center;
      align-items:center;
      cursor:pointer;
      z-index: 100;
      background-position: center;
      background-repeat: no-repeat;
      opacity:0.5;
      &:hover {

      }

      &.cycle-prev {
        background-image: url(/assets/img/ico_arrow_prev.svg);
        left:4px;

      }
      &.cycle-next {
        left:40px;
        background-image: url(/assets/img/ico_arrow_next.svg);
      }
      &.cycle-zoom{
        left:76px;
        background-image: url(/assets/img/ico_full.svg);
        pointer-events: none;
      }
      &.oneimage{
        left:4px;
      }
      &.disabled {
        opacity:0.3;
        cursor: default;
        &:active {

        }
      }
    }
  }
  .product-img-thumbs-wrapper {
    position: relative;
    margin-top:20px;
    margin-left:3px;
    width: calc(100% - 6px);
    @media(max-width:300px) {
      display:none;
    }
    .product-img-thumbs {
      margin:0;
      padding-bottom:3px;
      .thumb-item {
        display: inline-block;
        vertical-align: top;
        margin-top:1px;
        margin-bottom:10px;
        margin-right: 20px;
        position: relative;

        height:100%;
        cursor: pointer;
        .thumb-item-frame {
          position: relative;
          height:100%;
          width:100%;
          top:0;
          left:0;
          background-size:contain;
          background-position: center;
          background-repeat: no-repeat;
          border:$border;
          box-shadow:$gallery-box-shadow;
          img {
            display:block;
            opacity:1;
          }
        }
        &.cycle-slide-active {
          .thumb-item-frame {
            border-color:$active-slide;
          }
        }
      }
    }
  }
  &.cover {
    .product-img-middle {
      .product-img-body {
        div {
          a { background-size:cover;  }
        }
      }
    }
  }
  &.contain {
    .product-img-middle {
      .product-img-body {
        div {
          a { background-size:contain;  }
        }
      }
    }
  }
}


$title-font-family:	"proxima-nova", sans-serif;
$standard-font-family: "proxima-nova", sans-serif;

$container: 1170px;

$border-width: 15px;


$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
$max-width: 100%;

$background-dark:#363636;
$imgpath: '../../../../assets/img/';


.inner-wrap {
  margin: 5% 9%;
  @media(max-width:1500px) {
    margin: 5% ;
  }
  @media(max-width:1000px) {
    margin: 5% 20px;
  }
  .row{
    @media(max-width:1000px) {
      display: block;
    }
    .col{
      &:nth-child(odd){
        @media(max-width:1200px) {
          padding-left: 30px;
        }
        @media(max-width:1000px) {
          width: 90%;
          padding-left: 0px;
          padding-right: 0px;
          margin: 0 auto;
        }
      }
      &:nth-child(even){
        padding-left: 60px;
        @media(max-width:1200px) {
          padding-left: 30px;
        }
        @media(max-width:1000px) {
          width: 90%;
          padding-left: 0px;
          padding-right: 0px;
          margin: 0 auto;
        }
      }

    }
  }
}

.verticalalign{
  display:flex;
  padding-top: 50px;
  .tempdiv{
    width: 100%;
  }
}

.generalcontent{
  padding-bottom: 40px;
  .row{
    .col{

      h1{
        font-size: 36px;
        margin-bottom: 10px;
        text-transform: uppercase;
        border-bottom: 1px solid #5F5F5F;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
      .description{
        padding-bottom: 15px;
        margin-bottom: 30px;
        hr{
          border-top: 1px solid #5F5F5F;
          margin-top: 50px;
          margin-bottom: 30px;
        }
        h2{
          font-size: 24px;
          margin-bottom: 0;
          margin-top: 0;
          text-transform: uppercase;
          padding-bottom: 20px;
          padding-top: 20px;
        }
        p{
          &:first-child{
            font-size: 27px;
            line-height: 34px;
          }
        }
        a {
          color:#212529;
          text-decoration: underline;
        }
        ul{
          margin-left: 16px;
          li{
            list-style: circle;
            color: #fff;
          }
        }
      }
      img.imagemargin{
        margin-bottom: 35px;
        width: 100%;
      }
    }
  }
}

h1,h2 {
  font-family:$title-font-family;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 2rem;

}

h1 {
  letter-spacing: 0;
  font-style: normal;
  font-size: 27px;
  line-height: 1.2;
  text-transform: uppercase;

}

h2 {
  font-size: 42px;
  line-height: 1.2;
  text-transform: uppercase;

}

p{
  margin-bottom: 2rem;
}

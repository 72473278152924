.sculpturelayout{

}
.sculpture-sizer, .sculpture-item {
  width: calc(25% - 20px);
  height: auto;
  margin: 0 10px 20px 10px;
  @media(max-width:1000px) {
    width: calc(33.333% - 20px);
  }
  @media(max-width:700px) {
    width: calc(50% - 20px);
  }
  @media(max-width:500px) {
    width: calc(100% - 20px);
  }

  .img-wrap{

  }
  .text-wrap{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    display: block;
    padding: 20px;
    opacity: 0;
    display:flex;
    align-items:flex-end;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
    h3{
      position: absolute;
      bottom: 10px;
      font-size: 14px;
    }
  }
  &:hover {
    .text-wrap {
      opacity: 1;
    }
  }
}

.sculpture-details{
  .row{
    .col{
      color:#fff;
      width: 50%;
      h1{
        font-size: 36px;
        margin-bottom: 10px;
        text-transform: uppercase;
        @media(max-width:890px) {
          font-size: 26px;
        }
      }
      h2{
        font-size: 15px;
        text-transform: uppercase;
        width: 100%;
        border-bottom: 1px solid #5F5F5F;
        padding-bottom: 30px;
        margin-bottom: 30px;
        a{
          color: #fff;
          text-decoration: none;
          border-left: 1px solid #fff;
          padding-left: 15px;
          margin-left: 15px;
        }
      }
      .description{
        border-bottom: 1px solid #5F5F5F;
        padding-bottom: 15px;
        margin-bottom: 30px;
      }
      &:nth-child(odd){
        padding-right: 30px;
        @media(max-width:1000px) {
          padding-right: 0;
        }
      }
      &:nth-child(even){
        padding-left: 30px;
        @media(max-width:1000px) {
          padding-left: 0;
          padding-bottom: 80px;
        }
      }
    }
  }
}

.accreditation{
  color: #808080;
  margin-top: 20px;
  a{
    color: #808080;
    text-decoration: underline;
  }
}

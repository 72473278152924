.video-outter{
  max-width: 958px;
  width: 90%;
  margin: 0 auto;
  h1{
    font-size: 36px;
    margin: 40px 0 30px 0;
  }
  .description{
    width: 60%;
    margin-bottom: 80px;
    @media(max-width:996px) { width: 100%; }
  }
}

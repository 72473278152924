#mobile-nav-items {
  display: block;
  position: fixed;
  top: 0;
  width: 100vw;
  margin: 0 auto;
  bottom: 0;
  height: 100vh;
  z-index: 50;
  text-align: left;
  background: #1d1d1d;
  background-image: url(/assets/img/bck_menu.png);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  .inner {
    width: 90%;
    max-width: 840px;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 810px) {
      display: block;
      width: auto;
    }
    @media (max-width: 506px) {
      width: 90%;
    }
    .col {
      flex-direction: row;
      display: block;
      flex-basis: auto;
      flex-grow: 0;

      &:first-child {
        width: 35%;
        @media (max-width: 810px) {
          display: block;
          width: 100%;
          padding-bottom: 40px;
        }
        @media (max-width: 506px) {
          padding-bottom: 20px;
        }
        @media (max-height: 665px) {
          display: none;
        }
      }
      &:last-child {
        width: 65%;
        @media (max-width: 810px) {
          display: block;
          width: 100%;
        }
      }
      .logo {
        display: flex;
        flex-direction: column;
        padding: 0;
        a {
          color: #fff;
        }
        span {
          color: #fff;
          font-size: 14px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          color: #fff;
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          display: block;
          text-align: left;
          font-size: 36px;
          font-family: $title-font-family;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 40px;
          @media (max-width: 506px) {
            font-size: 26px;
            margin-bottom: 20px;
          }
          a {
            color: #fff;
            text-decoration: none;
          }
          ul.second {
            margin: 5px 0 25px;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 506px) {
              display: block;
              width: 100%;
            }
            li {
              border-bottom: 0;
              display: flex;
              padding: 0 14px;
              border-right: 1px solid #fff;
              text-transform: none;
              margin-bottom: 0;
              @media (max-width: 506px) {
                border-right: none;
                padding: 0;
                margin-bottom: 10px;
              }

              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                border-right: none;
              }
              a {
                padding: 0;
                font-size: 15px;
                text-transform: uppercase;
                display: inline-block;
                line-height: 12px;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          ul.open {
            display: block;
          }
          &.active {
            color: #76a958;
            span {
              color: #76a958;
            }
            ul.second {
              display: block;
              li {
                a {
                  color: #fff;
                  &.active {
                    text-decoration: underline;
                  }
                }
              }
            }
            > a {
              color: #76a958;
            }
          }
        }
      }
    }
  }
}

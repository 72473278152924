.exhibit-outter {
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
  .soloexhibit {
    h1.story-title {
      font-size: 36px;
      margin-bottom: 10px;
      text-transform: uppercase;
      border-bottom: 1px solid #5f5f5f;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    .exhibititem {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid #5f5f5f;
      display: flex;
      align-items: center;
      @media (max-width: 890px) {
        display: block;
      }
      .img-wrap {
        width: 364px;
        margin-right: 50px;
        img {
          width: 100%;
        }
        @media (max-width: 890px) {
          width: 100%;
          margin-bottom: 50px;
        }
      }
      .text-wrap {
        width: calc(100% - 364px - 50px);
        @media (max-width: 890px) {
          width: 100%;
        }
        &.fullwidth {
          width: 100%;
        }
        h3 {
          width: 100%;
          font-size: 23px;
        }
        a {
          color: #fff;
          text-decoration: underline;
        }
        ul {
          margin-left: 16px;
          li {
            list-style: circle;
            color: #fff;
          }
        }
      }
    }
  }
  .selectedexhibit {
    margin-bottom: 160px;
    margin-top: 80px;
  }
  h2 {
    font-size: 36px;
    margin: 40px 0 50px 0;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  .exhibit {
    width: 60%;
    margin-bottom: 80px;
    @media (max-width: 996px) {
      width: 100%;
    }
  }
}
.margintopstory {
  margin-top: 5%;
}
.linebreak {
  color: white;
  display: inline-block;
  margin: 0 10px;
}

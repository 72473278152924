.logo{
  padding: 25px;
  z-index: 2;
  position: relative;
  a{
    text-transform: uppercase;
    font-size: 26px;
    color: #5B5B5B;
    &:hover{
      text-decoration: none;
    }
  }
}

#mobile-burger-btn {
  margin:0;
  transition: right 0.5s;
  width: 28px;
  height: 19px;
  z-index: 100;
  position: fixed;
  top: 54px;
  right: 41px;
  float: right;
  padding: 0;
  cursor: pointer;
  background-image: url(/assets/img/icon_menu.svg);
  background-repeat: no-repeat;
  background-size: contain;
  &.menu-open {
    background-image: url(/assets/img/icon_menu_close.svg);
  }
}

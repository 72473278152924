.slideshow {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  width: calc(100vw - #{$border-width} - #{$border-width});
  height: calc(100vh - #{$border-width} - #{$border-width});
  margin-bottom: 0 !important;
  .detail{
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: calc(100vh - #{$border-width} - #{$border-width});
    .slifeinfo{
      position: absolute;
      z-index: 20;
      width: 100%;
      bottom: 31px;
      left: 130px;
      font-size: 15px;
      color: #5B5B5B;
      display: inline;
      h3{
        float: left;
      }
      a{
        font-size: 15px;
        color: #5B5B5B;
        float: left;
        text-decoration: underline;
        margin-left: 6px;
        top: -2px;
      }
    }
  }
}
.slidenav{
  position: absolute;
  z-index: 20;
  width: 100%;
  top: calc(100% - 50px);
  left: 30px;
  z-index: 3;
}

.generalbutton{
  background: #5B5B5B;
  padding: 10px 20px;
  color: #fff;
}

.prev, .next {

  background-position: center;
  background-repeat: no-repeat;
}
.prev {
  background-image: url(/assets/img/ico_arrow_prev.svg);
  margin-right: 4px;
}
.next {
  background-image: url(/assets/img/ico_arrow_next.svg);
}

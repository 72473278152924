html {
  box-sizing: border-box;
}

body {
  font-family:$standard-font-family;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: 300;
	line-height: 1.5;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  font-family: $standard-font-family;
  margin-bottom: 2rem;
}

strong {
  font-weight:700;
}

hr{
  border-top: 2px solid #D8D8D8;
  margin: 0;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}


.page-wrap {
	position: relative;
  background: #fff;
	overflow: hidden;
  margin:15px;
	.page-inner-wrap {
		margin:0 auto;
    background:#fff;
		position: relative;
		right: 0;
		z-index: 100;
		transition: right 0.3s ease;
		min-height: calc(100vh - #{$border-width} - #{$border-width});
    overflow: hidden;
		a {
			position: relative;
		}
    &.push {
			right: calc(100vw + 30px);
		}
	}
}


.dark{
  .page-wrap .page-inner-wrap {
    background-color: $background-dark;
    .logo a{
      color:#fff;
    }
    h1,h2,h3,h4,p,a{ color: #fff;}
  }
  #mobile-burger-btn {
    background-image: url(/assets/img/icon_menu_white.svg);
  }
  .generalcontent{
    .row{
      .col {
        color:#fff;
      }
    }
  }
}

#top, #bottom, #left, #right {
  background: #EDEDED;
  position: fixed;
  z-index: 1000;
}
#left, #right {
	top: 0; bottom: 0;
	width: 15px;
}
#left { left: 0; }
#right { right: 0; }

#top, #bottom {
	left: 0; right: 0;
	height: 15px;
}
#top { top: 0; }
#bottom { bottom: 0; }
